import {BerechnungsartDTO, BerechnungsartDTOBerechnungsartEnum} from '../../openapi/fakturierung-openapi';


export const berechnungsartenMock: BerechnungsartDTO[] = [
  {
    id: 'Netto',
    berechnungsart: BerechnungsartDTOBerechnungsartEnum.Netto,
  },
  {
    id: 'Brutto',
    berechnungsart: BerechnungsartDTOBerechnungsartEnum.Brutto,
  },
];
