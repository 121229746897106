import {UstProzentsatzDTO} from '../../openapi/fakturierung-openapi';


export const umsatzsteuersaetzeMock: UstProzentsatzDTO[] = [
  {
    id: '0',
    prozentsatz: 0.19,
  },
  {
    id: '1',
    prozentsatz: 0.07,
  },
  {
    id: '2',
    prozentsatz: 0.09,
  },
  {
    id: '3',
    prozentsatz: 0.055,
  },
  {
    id: '4',
    prozentsatz: 0,
  }
];
