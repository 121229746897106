import {createAction, props} from '@ngrx/store';
import {
  AbsenderDTO,
  CreateAbsenderGeschaeftskundeRequestDTO, CreateAbsenderGeschaeftskundeResponseDTO,
  CreateAbsenderPrivatpersonRequestDTO, CreateAbsenderPrivatpersonResponseDTO,
  UpdateAbsenderGeschaeftskundeRequestDTO,
  UpdateAbsenderGeschaeftskundeResponseDTO,
  UpdateAbsenderPrivatpersonRequestDTO,
  UpdateAbsenderPrivatpersonResponseDTO,
} from '../../openapi/fakturierung-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class AbsenderEntitiesActions {

  public static readAbsenderSuccess = createAction(
    '[Absender Entities] Read list of absenders successfully.',
    props<{
      absenderDtos: AbsenderDTO[]
    }>(),
  );

  public static readAbsenderFailed = createAction(
    '[Absender Entities] Read list of absenders failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static createAbsenderPrivatperson = createAction(
    '[Absender Entities] Create absender privatperson.',
    props<{
      inhaberId: string,
      requestDto: CreateAbsenderPrivatpersonRequestDTO,
    }>(),
  );

  public static createAbsenderPrivatpersonSuccess = createAction(
    '[Absender Entities] Create absender privatperson successfully.',
    props<{ responseDto: CreateAbsenderPrivatpersonResponseDTO }>(),
  );

  public static createAbsenderPrivatpersonFailure = createAction(
    '[Absender Entities] Create absender privatperson failed.',
    props<{ error: any }>(),
  );

  public static createAbsenderGeschaeftskunde = createAction(
    '[Absender Entities] Create absender geschaeftskunde.',
    props<{
      inhaberId: string,
      requestDto: CreateAbsenderGeschaeftskundeRequestDTO,
    }>(),
  );

  public static createAbsenderGeschaeftskundeSuccess = createAction(
    '[Absender Entities] Create absender geschaeftskunde successfully.',
    props<{ responseDto: CreateAbsenderGeschaeftskundeResponseDTO }>(),
  );

  public static createAbsenderGeschaeftskundeFailure = createAction(
    '[Absender Entities] Create absender geschaeftskunde failed.',
    props<{ error: any }>(),
  );

  public static updateAbsenderPrivatperson = createAction(
    '[Absender Entities] Update absender privatperson.',
    props<{
      inhaberId: string,
      absenderId: string,
      requestDto: UpdateAbsenderPrivatpersonRequestDTO,
    }>(),
  );

  public static updateAbsenderPrivatpersonSuccess = createAction(
    '[Absender Entities] Update absender privatperson successfully.',
    props<{ responseDto: UpdateAbsenderPrivatpersonResponseDTO }>(),
  );

  public static updateAbsenderPrivatpersonFailure = createAction(
    '[Absender Entities] Update absender geschaeftskunde failed.',
    props<{ error: any }>(),
  );

  public static updateAbsenderGeschaeftskunde = createAction(
    '[Absender Entities] Update absender geschaeftskunde.',
    props<{
      inhaberId: string,
      absenderId: string,
      requestDto: UpdateAbsenderGeschaeftskundeRequestDTO,
    }>(),
  );

  public static updateAbsenderGeschaeftskundeSuccess = createAction(
    '[Absender Entities] Update absender geschaeftskunde successfully.',
    props<{ responseDto: UpdateAbsenderGeschaeftskundeResponseDTO }>(),
  );

  public static updateAbsenderGeschaeftskundeFailure = createAction(
    '[Absender Entities] Update absender geschaeftskunde failed.',
    props<{ error: any }>(),
  );

  public static readNextKundennummer = createAction(
    '[Absender Entities] Read next kundennummer.',
    props<{ inhaberId: string }>(),
  );

  public static readNextKundennummerSuccess = createAction(
    '[Absender Entities] Read next kundennummer successfully.',
    props<{ nextKundennummer: number }>(),
  );

  public static readNextKundennummerFailure = createAction(
    '[Absender Entities] Read next kundennummer failed.',
    props<{ error: any }>(),
  );
}
