import {AppState} from '../states/app.state';
import {identity} from 'rxjs';
import {createSelector} from '@ngrx/store';


export class AbsenderDialogSelectors {

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    isOpen: (state: AppState) => state.absenderDialog.isOpen,
    absenderId: (state: AppState) => state.absenderDialog.absenderId,
    laender: (state: AppState) => state.absenderDialog.laender,
  };

  public static isAbsenderDialogOpen = createSelector(
    this.select.isOpen,
    identity,
  );

  public static absenderId = createSelector(
    this.select.absenderId,
    identity,
  );

  public static laender = createSelector(
    this.select.laender,
    identity,
  );
}
