import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbsenderGeschaeftskundeFormControls} from './absender-geschaeftskunde-form.interface';
import {
  FormFieldNumberComponent,
  FormFieldSelectComponent,
  FormFieldTextComponent,
  OptionComponent
} from '@adnova/jf-ng-components';
import {MatDividerModule} from '@angular/material/divider';
import {ReactiveFormsModule} from '@angular/forms';
import {AbsenderFormType} from '../../../dialogs/absender-dialog/absender-form.type';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-absender-geschaeftskunde-form',
  standalone: true,
  imports: [CommonModule, FormFieldTextComponent, FormFieldNumberComponent, FormFieldSelectComponent, MatDividerModule, ReactiveFormsModule],
  templateUrl: './absender-geschaeftskunde-form.component.html',
  styleUrls: ['./absender-geschaeftskunde-form.component.scss']
})
export class AbsenderGeschaeftskundeFormComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  @Input() public formType?: AbsenderFormType;
  @Input() public formControls?: AbsenderGeschaeftskundeFormControls;
  @Input() public laenderOptions?: OptionComponent[];
  @Input() public anredenOptions?: OptionComponent[];

  private _kundennummerVorgeschlagen = '';

  get kundennummerVorgeschlagen(): string {
    return this._kundennummerVorgeschlagen;
  }

  set kundennummerVorgeschlagen(value: string) {
    this._kundennummerVorgeschlagen = value;
  }

  ngOnInit() {
    this.formControls?.kundennummer.statusChanges.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.kundennummerVorgeschlagen = this.formType === AbsenderFormType.CREATE && this.formControls?.kundennummer.pristine ? 'Vorgeschlagene Kundennummer' : '';
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
