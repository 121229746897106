import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  FormFieldNumberComponent,
  FormFieldSelectComponent,
  FormFieldTextComponent,
  OptionComponent
} from '@adnova/jf-ng-components';
import {AbsenderPrivatpersonFormControls} from './absender-privatperson-form.controls';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDividerModule} from '@angular/material/divider';
import {AbsenderFormType} from '../../../dialogs/absender-dialog/absender-form.type';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'app-absender-privatperson-form',
  standalone: true,
  imports: [CommonModule, FormFieldSelectComponent, FormFieldTextComponent, FormFieldNumberComponent, FormsModule, ReactiveFormsModule, MatDividerModule],
  templateUrl: './absender-privatperson-form.component.html',
  styleUrls: ['./absender-privatperson-form.component.scss']
})
export class AbsenderPrivatpersonFormComponent implements OnInit, OnDestroy{

  private readonly unsubscribe$ = new Subject<void>();

  @Input() public formType?: AbsenderFormType;
  @Input() public formControls?: AbsenderPrivatpersonFormControls;
  @Input() public laenderOptions?: OptionComponent[];
  @Input() public anredenOptions?: OptionComponent[];
  private _kundennummerVorgeschlagen = '';

  get kundennummerVorgeschlagen(): string {
    return this._kundennummerVorgeschlagen;
  }

  set kundennummerVorgeschlagen(value: string) {
    this._kundennummerVorgeschlagen = value;
  }

  ngOnInit() {
    this.formControls?.kundennummer.statusChanges.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.kundennummerVorgeschlagen = this.formType === AbsenderFormType.CREATE && this.formControls?.kundennummer.pristine ? 'Vorgeschlagene Kundennummer' : '';
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
