import {createAction} from '@ngrx/store';


export class UploadLogoDialogActions {

    public static open = createAction(
      '[Fakturierunsgbeleg-Form] Open upload logo dialog.',
    );

    public static close = createAction(
      '[Fakturierunsgbeleg-Form] Close upload logo dialog.',
    );
}
