import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/states/app.state';
import {FormInput} from '../../components/form/form-input.interface';
import {Subject, take} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AbsenderFormControls} from './absender-form.controls';
import {
  RadioButton
} from '@adnova/jf-ng-components/lib/generic/form-elements/form-fields/radio/radio-button/radio-button.interface';
import {AbsenderDialogActions} from '../../../store/actions/absender-dialog.actions';
import {
  AbsenderPrivatpersonFormControls
} from '../../components/forms/absender-privatperson-form/absender-privatperson-form.controls';
import {
  AbsenderGeschaeftskundeFormControls
} from '../../components/forms/absender-geschaeftskunde-form/absender-geschaeftskunde-form.interface';
import {map, takeUntil} from 'rxjs/operators';
import {AbsenderDialogSelectors} from '../../../store/selectors/absender-dialog.selectors';
import {AbsenderEntitiesActions} from '../../../store/actions/absender-entities.actions';
import {
  AbsenderDTO,
  AbsenderTypeDTO,
  CreateAbsenderGeschaeftskundeRequestDTO,
  CreateAbsenderPrivatpersonRequestDTO,
  UpdateAbsenderGeschaeftskundeRequestDTO,
  UpdateAbsenderPrivatpersonRequestDTO
} from '../../../openapi/fakturierung-openapi';
import {FormFieldSelectValue, InhaberEntitiesSelectors, OptionComponent} from '@adnova/jf-ng-components';
import {AbsenderEntitiesSelectors} from '../../../store/selectors/absender-entities.selectors';
import {AnredenDialogData} from '../../../interfaces/anreden-data.interface';
import {NGXLogger} from 'ngx-logger';
import {AbsenderFormType} from './absender-form.type';


@Component({
  selector: 'app-create-produkt-dialog',
  templateUrl: './absender-dialog.component.html',
  styleUrls: ['./absender-dialog.component.scss']
})
export class AbsenderDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  protected primaryButtonLabel = 'Kontakt anlegen';
  protected absenderDto?: AbsenderDTO;

  private readonly unsubscribe$ = new Subject<void>();
  private inhaberId?: string;
  private anredenData: AnredenDialogData[] = [
    {
      label: 'Frau',
      isSelected: false,
    },
    {
      label: 'Herr',
      isSelected: false,
    },
    {
      label: 'Nicht zutreffend',
      isSelected: false,
    },
  ];

  public _laenderGeschaeft: OptionComponent[] = [];
  private _laenderPrivat: OptionComponent[] = [];
  private _anreden: OptionComponent[] = [];
  private _absenderFormType = AbsenderFormType.CREATE;

  protected readonly absenderPrivat = 'privat';
  protected readonly absenderGeschaeft = 'geschaeft';
  protected nextKundennummer?: number;

  protected formControlsPrivat: AbsenderPrivatpersonFormControls = {
    anrede: new FormControl(null),
    titel: new FormControl(''),
    kundennummer: new FormControl(null, [Validators.required]),
    vorname: new FormControl('', [Validators.required]),
    nachname: new FormControl('', [Validators.required]),
    strasseundhausnummer: new FormControl('', [Validators.required]),
    plz: new FormControl('', [Validators.required]),
    ort: new FormControl('', [Validators.required]),
    landPrivat: new FormControl(null, [Validators.required]),
    email: new FormControl('', [Validators.email]),
  };

  protected formControlsGeschaeft: AbsenderGeschaeftskundeFormControls = {
    firma: new FormControl('', [Validators.required]),
    kundennummer: new FormControl(null, [Validators.required]),
    strasseundhausnummer: new FormControl('', [Validators.required]),
    plz: new FormControl('', [Validators.required]),
    ort: new FormControl('', [Validators.required]),
    landGeschaeft: new FormControl(null, [Validators.required]),
    ustId: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    ansprechpartnerAnrede: new FormControl(null),
    ansprechpartnerTitel: new FormControl(''),
    ansprechpartnerVorname: new FormControl(''),
    ansprechpartnerNachname: new FormControl(''),
  };

  protected absenderTypes: RadioButton[] = [
    {
      label: 'Geschäftskunde',
      value: this.absenderGeschaeft,
      isSelected: true,
      id: AbsenderTypeDTO.Geschaeftskunde,
    },
    {
      label: 'Privatperson',
      value: this.absenderPrivat,
      id: AbsenderTypeDTO.Privatperson,
    },
  ];

  protected formInputs: FormInput = {
    title: 'Neuen Kontakt anlegen',
    cancelButtonLabel: 'Abbrechen',
  };

  public formControls: AbsenderFormControls = {
    absenderType: new FormControl(null),
    privat: new FormGroup(this.formControlsPrivat),
    geschaeft: new FormGroup(this.formControlsGeschaeft),
  };

  public formGroup = new FormGroup(this.formControls);

  public selectedAbsenderType = this.absenderTypes.find(type => type.isSelected)?.value;

  get absenderFormType(): AbsenderFormType {
    return this._absenderFormType;
  }

  get laenderGeschaeft(): OptionComponent[] {
    return this._laenderGeschaeft;
  }

  get laenderPrivat(): OptionComponent[] {
    return this._laenderPrivat;
  }

  get anreden(): OptionComponent[] {
    return this._anreden;
  }

  constructor(
    private store: Store<AppState>,
    private logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    for (const anrede of this.anredenData) {
      const anredeOption = new OptionComponent();
      anredeOption.id = anrede.label;
      anredeOption.label = anrede.label;
      anredeOption.isSelected = anrede.isSelected;

      this._anreden.push(anredeOption);
    }
    // INFO: Inhaber ID
    this.store.select(InhaberEntitiesSelectors.currentInhaber).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaber => {
      this.inhaberId = inhaber?.id;
    });

    // INFO: Absender
    this.store.select(AbsenderDialogSelectors.absenderId).pipe(
      take(1),
    ).subscribe(absenderId => {

      if (!absenderId) return;

      this.store.select(AbsenderEntitiesSelectors.absenderById(absenderId)).pipe(
        take(1),
      ).subscribe(absenderDto => {

        this.formInputs.title = 'Kontakt bearbeiten';
        this.primaryButtonLabel = 'Speichern';

        if (absenderDto) {
          this._absenderFormType = AbsenderFormType.EDIT;

          this.absenderDto = absenderDto;

          if (absenderDto.type) {
            this.absenderTypes.map(absenderType => {
              absenderType.label == absenderDto.type ? absenderType.isSelected = true : absenderType.isSelected = false;
            });
          }

          if (absenderDto.anrede) {
            this.anreden.map(anrede => {
              anrede.label == absenderDto.anrede ? anrede.isSelected = true : anrede.isSelected = false;
            });
          }

          if (absenderDto.anrede) {
            this.formControls.privat.get('anrede')?.setValue(absenderDto.anrede);
            this.formControls.geschaeft.get('ansprechpartnerAnrede')?.setValue(absenderDto.anrede);
          }

          if (absenderDto.nachname) {
            this.formControls.privat.get('nachname')?.setValue(absenderDto.nachname);
            this.formControls.geschaeft.get('ansprechpartnerNachname')?.setValue(absenderDto.nachname);
          }

          if (absenderDto.vorname) {
            this.formControls.privat.get('vorname')?.setValue(absenderDto.vorname);
            this.formControls.geschaeft.get('ansprechpartnerVorname')?.setValue(absenderDto.vorname);
          }

          if (absenderDto.firma) {
            this.formControls.geschaeft.get('firma')?.setValue(absenderDto.firma);
          }

          if (absenderDto.kundennummer) {
            this.formControls.privat.get('kundennummer')?.setValue(absenderDto.kundennummer);
            this.formControls.geschaeft.get('kundennummer')?.setValue(absenderDto.kundennummer);
          }

          if (absenderDto.strasseundhausnummer) {
            this.formControls.privat.get('strasseundhausnummer')?.setValue(absenderDto.strasseundhausnummer);
            this.formControls.geschaeft.get('strasseundhausnummer')?.setValue(absenderDto.strasseundhausnummer);
          }

          if (absenderDto.plz) {
            this.formControls.privat.get('plz')?.setValue(absenderDto.plz);
            this.formControls.geschaeft.get('plz')?.setValue(absenderDto.plz);
          }

          if (absenderDto.ort) {
            this.formControls.privat.get('ort')?.setValue(absenderDto.ort);
            this.formControls.geschaeft.get('ort')?.setValue(absenderDto.ort);
          }

          if (absenderDto.ustId) {
            this.formControls.geschaeft.get('ustId')?.setValue(absenderDto.ustId);
          }

          if (absenderDto.emailAdresse) {
            this.formControls.privat.get('email')?.setValue(absenderDto.emailAdresse);
            this.formControls.geschaeft.get('email')?.setValue(absenderDto.emailAdresse);
          }

          if (absenderDto.titel) {
            this.formControls.privat.get('titel')?.setValue(absenderDto.titel);
            this.formControls.geschaeft.get('ansprechpartnerTitel')?.setValue(absenderDto.titel);
          }
        } else {
          this.logger.error('absender not found by id at absender dialog.', absenderId);
        }
      });
    });

    // INFO: Next Kundennummer
    this.store.select(AbsenderEntitiesSelectors.nextKundennummer).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(kundennummer => {
      this.formControls.privat.get('kundennummer')?.setValue(kundennummer);
      this.formControls.geschaeft.get('kundennummer')?.setValue(kundennummer);
    });

    // INFO: Laender
    this.store.select(AbsenderDialogSelectors.laender).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(laender => {

      if (!laender) return;

      for (const land of laender) {
        const option = new OptionComponent();
        option.id = land.landId;
        option.label = land.land || '';
        option.isSelected = !!(this.absenderDto && this.absenderDto.land && this.absenderDto.land.land == option.label);

        this._laenderPrivat.push(option);
        this._laenderGeschaeft.push(option);
      }
    });

    this.formControls.absenderType.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      if (!value || !value.value) return;
      this.selectedAbsenderType = value.value;
    });

    this.formControls.geschaeft.valueChanges.pipe(
    ).subscribe(value => {
      this.updatePrivateFormValueChanges(value);
    });

    this.formControls.privat.valueChanges.pipe(
    ).subscribe(value => {
      this.updateGeschaeftFormValueChanges(value);
    });
  }

  ngAfterViewInit(): void {
    this.formControls.geschaeft.get('landGeschaeft')?.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      map(value => value as FormFieldSelectValue),
    ).subscribe(value => {
      const id = value.selectedOptionValueIds?.[0];
      const selectedOption = this.laenderGeschaeft.find(option => {
        return option.id === id;
      });
      const ustIdFormField = this.formControls.geschaeft.get('ustId');

      // FIXME: Sobald die API die Id des Landes Deutschland bereitstellt muss auf die korrekte Id verglichen werden.
      if (selectedOption?.id === '1') {
        ustIdFormField?.setValidators(null);
      } else {
        ustIdFormField?.setValidators([Validators.required]);
      }
      ustIdFormField?.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Aktualisiert die Werte des Geschäftskunden-Formulars, wenn sich die Werte des Privatpersonen-Formulars ändern.
   *
   * @param value
   * @private
   */
  private updateGeschaeftFormValueChanges(value: any): void {
    const newAnredeValue = value.anrede;
    const oldAnredeValue = this.formControls.privat.get('ansprechpartnerAnrede')?.value;
    if (newAnredeValue !== oldAnredeValue) {
      let anrede = this.formControls.geschaeft.get('ansprechpartnerAnrede')!;
      anrede.setValue(newAnredeValue);
      anrede.markAsTouched();
    }

    const newTitelValue = value.titel;
    const oldTitelValue = this.formControls.geschaeft.get('ansprechpartnerTitel')?.value;
    if (newTitelValue !== oldTitelValue) {
      let titel = this.formControls.geschaeft.get('ansprechpartnerTitel')!;
      titel.setValue(newTitelValue);
      titel.markAsTouched();
    }

    const newKundennummerValue = value.kundennummer;
    const oldKundennummerValue = this.formControls.geschaeft.get('kundennummer')?.value;
    if (newKundennummerValue !== oldKundennummerValue) {
      let kundennummer = this.formControls.geschaeft.get('kundennummer')!;
      kundennummer.setValue(newKundennummerValue);
      kundennummer.markAsTouched();
    }

    const newVornameValue = value.vorname;
    const oldVornameValue = this.formControls.geschaeft.get('ansprechpartnerVorname')?.value;
    if (newVornameValue !== oldVornameValue) {
      let vorname = this.formControls.geschaeft.get('ansprechpartnerVorname')!;
      vorname.setValue(newVornameValue);
      vorname.markAsTouched();
    }

    const newNachnameValue = value.nachname;
    const oldNachnameValue = this.formControls.geschaeft.get('ansprechpartnerNachname')?.value;
    if (newNachnameValue !== oldNachnameValue) {
      let nachname = this.formControls.geschaeft.get('ansprechpartnerNachname')!;
      nachname.setValue(newNachnameValue);
      nachname.markAsTouched();
    }

    const newStrasseundHausnummerValue = value.strasseundhausnummer;
    const oldStrasseundHausnummerValue = this.formControls.geschaeft.get('strasseundhausnummer')?.value;
    if (newStrasseundHausnummerValue !== oldStrasseundHausnummerValue) {
      let strasseundHausnummer = this.formControls.geschaeft.get('strasseundhausnummer')!;
      strasseundHausnummer.setValue(newStrasseundHausnummerValue);
      strasseundHausnummer.markAsTouched();
    }

    const newPlzValue = value.plz;
    const oldPlzValue = this.formControls.geschaeft.get('plz')?.value;
    if (newPlzValue !== oldPlzValue) {
      let plz = this.formControls.geschaeft.get('plz')!;
      plz.setValue(newPlzValue);
      plz.markAsTouched();
    }

    const newOrtValue = value.ort;
    const oldOrtValue = this.formControls.geschaeft.get('ort')?.value;
    if (newOrtValue !== oldOrtValue) {
      let ort = this.formControls.geschaeft.get('ort')!;
      ort.setValue(newOrtValue);
      ort.markAsTouched();
    }

    const newLandGeschaeftValue = value.landPrivat;
    const oldLandGeschaeftValue = this.formControls.geschaeft.get('landGeschaeft')?.value;
    if (newLandGeschaeftValue !== oldLandGeschaeftValue) {
      let landGeschaeft = this.formControls.geschaeft.get('landGeschaeft')!;
      landGeschaeft.setValue(newLandGeschaeftValue);
      landGeschaeft.markAsTouched();
    }

    const newEmailValue = value.email;
    const oldEmailValue = this.formControls.geschaeft.get('email')?.value;
    if (newEmailValue !== oldEmailValue) {
      let email = this.formControls.geschaeft.get('email')!;
      email.setValue(newEmailValue);
      email.markAsTouched();
    }
  }

  /**
   * Aktualisiert die Werte des Privatpersonen-Formulars, wenn sich die Werte des Geschäftskunden-Formulars ändern.
   *
   * @param value
   * @private
   */
  private updatePrivateFormValueChanges(value: any): void {
    const newAnredeValue = value.ansprechpartnerAnrede;
    const oldAnredeValue = this.formControls.privat.get('anrede')?.value;
    if (newAnredeValue !== oldAnredeValue) {
      let anrede = this.formControls.privat.get('anrede')!;
      anrede.setValue(newAnredeValue);
      anrede.markAsTouched();
    }

    const newTitelValue = value.ansprechpartnerTitel;
    const oldTitelValue = this.formControls.privat.get('titel')?.value;
    if (newTitelValue !== oldTitelValue) {
      let titel = this.formControls.privat.get('titel')!;
      titel.setValue(newTitelValue);
      titel.markAsTouched();
    }

    const newKundennummerValue = value.kundennummer;
    const oldKundennummerValue = this.formControls.privat.get('kundennummer')?.value;
    if (newKundennummerValue !== oldKundennummerValue) {
      let kundennummer = this.formControls.privat.get('kundennummer')!;
      kundennummer.setValue(newKundennummerValue);
      kundennummer.markAsTouched();
    }

    const newVornameValue = value.ansprechpartnerVorname;
    const oldVornameValue = this.formControls.privat.get('vorname')?.value;
    if (newVornameValue !== oldVornameValue) {
      let vorname = this.formControls.privat.get('vorname')!;
      vorname.setValue(newVornameValue);
      vorname.markAsTouched();
    }

    const newNachnameValue = value.ansprechpartnerNachname;
    const oldNachnameValue = this.formControls.privat.get('nachname')?.value;
    if (newNachnameValue !== oldNachnameValue) {
      let nachname = this.formControls.privat.get('nachname')!;
      nachname.setValue(newNachnameValue);
      nachname.markAsTouched();
    }

    const newStrasseundHausnummerValue = value.strasseundhausnummer;
    const oldStrasseundHausnummerValue = this.formControls.privat.get('strasseundhausnummer')?.value;
    if (newStrasseundHausnummerValue !== oldStrasseundHausnummerValue) {
      let strasseundHausnummer = this.formControls.privat.get('strasseundhausnummer')!;
      strasseundHausnummer.setValue(newStrasseundHausnummerValue);
      strasseundHausnummer.markAsTouched();
    }

    const newPlzValue = value.plz;
    const oldPlzValue = this.formControls.privat.get('plz')?.value;
    if (newPlzValue !== oldPlzValue) {
      let plz = this.formControls.privat.get('plz')!;
      plz.setValue(newPlzValue);
      plz.markAsTouched();
    }

    const newOrtValue = value.ort;
    const oldOrtValue = this.formControls.privat.get('ort')?.value;
    if (newOrtValue !== oldOrtValue) {
      let ort = this.formControls.privat.get('ort')!;
      ort.setValue(newOrtValue);
      ort.markAsTouched();
    }

    const newLandPrivatValue = value.landGeschaeft;
    const oldLandPrivatValue = this.formControls.privat.get('landPrivat')?.value;
    if (newLandPrivatValue !== oldLandPrivatValue) {
      let landPrivat = this.formControls.privat.get('landPrivat')!;
      landPrivat.setValue(newLandPrivatValue);
      landPrivat.markAsTouched();
    }

    const newEmailValue = value.email;
    const oldEmailValue = this.formControls.privat.get('email')?.value;
    if (newEmailValue !== oldEmailValue) {
      let email = this.formControls.privat.get('email')!;
      email.setValue(newEmailValue);
      email.markAsTouched();
    }
  }

  confirmActionClick(): void {
    if (this.absenderFormType === AbsenderFormType.CREATE) {
      this.confirmCreate();
    } else if (this.absenderFormType === AbsenderFormType.EDIT) {
      this.confirmUpdate();
    }
  }

  protected confirmUpdate(): void {
    if (!this.inhaberId || !this.absenderDto || !this.absenderDto.id) return;

    const absenderType = this.formControls.absenderType.value?.value;
    if (absenderType === this.absenderPrivat) {
      this.store.dispatch(AbsenderEntitiesActions.updateAbsenderPrivatperson({
        inhaberId: this.inhaberId!,
        absenderId: this.absenderDto.id,
        requestDto: this.createUpdateAbsenderPrivatpersonDTO(),
      }));
    } else {
      this.store.dispatch(AbsenderEntitiesActions.updateAbsenderGeschaeftskunde({
        inhaberId: this.inhaberId!,
        absenderId: this.absenderDto.id,
        requestDto: this.createUpdateAbsenderGeschaeftskundeRequestDTO(),
      }));
    }
  }

  protected confirmCreate(): void {
    const absenderType = this.formControls.absenderType.value?.value;
    if (absenderType === this.absenderPrivat) {
      this.store.dispatch(AbsenderEntitiesActions.createAbsenderPrivatperson({
        inhaberId: this.inhaberId!,
        requestDto: this.createCreateAbsenderPrivatpersonRequestDTO(),
      }));
    } else if (absenderType === this.absenderGeschaeft) {
      this.store.dispatch(AbsenderEntitiesActions.createAbsenderGeschaeftskunde({
        inhaberId: this.inhaberId!,
        requestDto: this.createCreateAbsenderGeschaeftskundeRequestDTO(),
      }));
    }
  }

  private createUpdateAbsenderPrivatpersonDTO(): UpdateAbsenderPrivatpersonRequestDTO {
    return {
      anrede: this.formControls.privat.get('anrede')?.value,
      ort: this.formControls.privat.get('ort')?.value,
      kundennummer: this.formControls.privat.get('kundennummer')?.value,
      strasseundhausnummer: this.formControls.privat.get('strasseundhausnummer')?.value,
      land: this.formControls.privat.get('landPrivat')?.value,
      nachname: this.formControls.privat.get('nachname')?.value,
      plz: this.formControls.privat.get('plz')?.value,
      titel: this.formControls.privat.get('titel')?.value,
      vorname: this.formControls.privat.get('vorname')?.value,
      emailAdresse: this.formControls.privat.get('email')?.value,
    };
  }

  private createUpdateAbsenderGeschaeftskundeRequestDTO(): UpdateAbsenderGeschaeftskundeRequestDTO {
    return {
      firma: this.formControls.geschaeft.get('firma')?.value,
      ort: this.formControls.geschaeft.get('ort')?.value,
      kundennummer: this.formControls.geschaeft.get('kundennummer')?.value,
      strasseundhausnummer: this.formControls.geschaeft.get('strasseundhausnummer')?.value,
      plz: this.formControls.geschaeft.get('plz')?.value,
      land: this.formControls.geschaeft.get('landGeschaeft')?.value,
      ustId: this.formControls.geschaeft.get('ustId')?.value,
      emailAdresse: this.formControls.geschaeft.get('email')?.value,
      ansprechpartner: {
        anrede: this.formControls.geschaeft.get('ansprechpartnerAnrede')?.value,
        nachname: this.formControls.geschaeft.get('ansprechpartnerNachname')?.value,
        titel: this.formControls.geschaeft.get('ansprechpartnerTitel')?.value,
        vorname: this.formControls.geschaeft.get('ansprechpartnerVorname')?.value,
      }
    };
  }

  private createCreateAbsenderPrivatpersonRequestDTO(): CreateAbsenderPrivatpersonRequestDTO {
    return {
      anrede: this.formControls.privat.get('anrede')?.value,
      ort: this.formControls.privat.get('ort')?.value,
      kundennummer: this.formControls.privat.get('kundennummer')?.value,
      strasseundhausnummer: this.formControls.privat.get('strasseundhausnummer')?.value,
      land: this.formControls.privat.get('landPrivat')?.value,
      nachname: this.formControls.privat.get('nachname')?.value,
      plz: this.formControls.privat.get('plz')?.value,
      titel: this.formControls.privat.get('titel')?.value,
      vorname: this.formControls.privat.get('vorname')?.value,
      emailAdresse: this.formControls.privat.get('email')?.value,
    };
  }

  private createCreateAbsenderGeschaeftskundeRequestDTO(): CreateAbsenderGeschaeftskundeRequestDTO {
    return {
      firma: this.formControls.geschaeft.get('firma')?.value,
      ort: this.formControls.geschaeft.get('ort')?.value,
      kundennummer: this.formControls.geschaeft.get('kundennummer')?.value,
      strasseundhausnummer: this.formControls.geschaeft.get('strasseundhausnummer')?.value,
      plz: this.formControls.geschaeft.get('plz')?.value,
      land: this.formControls.geschaeft.get('landGeschaeft')?.value,
      ustId: this.formControls.geschaeft.get('ustId')?.value,
      emailAdresse: this.formControls.geschaeft.get('email')?.value,
      ansprechpartner: {
        anrede: this.formControls.geschaeft.get('ansprechpartnerAnrede')?.value,
        nachname: this.formControls.geschaeft.get('ansprechpartnerNachname')?.value,
        titel: this.formControls.geschaeft.get('ansprechpartnerTitel')?.value,
        vorname: this.formControls.geschaeft.get('ansprechpartnerVorname')?.value,
      }
    };
  }

  protected closeDialogClick(): void {
    this.store.dispatch(AbsenderDialogActions.close());
  };

  /**
   * Überprüft, ob das Formular vollständig ist. Wenn folgende Bedingungen erfüllt sind, wird true zurückgegeben:
   * 1. Es muss geprüft werden, ob der ausgewählte Absender-Typ verändert worden ist.
   * 2. Es muss geprüft werden, ob die Werte des Privatpersonen-Formulars verändert wurden und valide sind.
   * 3. Es muss geprüft werden, ob die Werte des Geschäftskunden-Formulars verändert wurden und valide sind.
   *
   * @protected
   */
  protected checkFormComplete(): boolean {
    return this.formGroup.controls.absenderType.dirty
      || (this.selectedAbsenderType === this.absenderPrivat
        && this.formGroup.controls.privat.valid
        && this.formGroup.controls.privat.dirty)
      || (this.selectedAbsenderType === this.absenderGeschaeft
        && this.formGroup.controls.geschaeft.valid
        && this.formGroup.controls.geschaeft.dirty);
  }
}

