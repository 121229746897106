import {AbsenderDialogState} from '../states/absender-dialog.state';
import {AbsenderDialogActions} from '../actions/absender-dialog.actions';
import {LandDTO} from '../../openapi/fakturierung-openapi';
import {createReducer, on} from '@ngrx/store';


export const initialAbsenderDialogState: AbsenderDialogState = {
  isOpen: false,
  absenderId: undefined,
  laender: [  // FIXME: Mock entfernen
    {
      landId: '1',
      land: 'Deutschland',
    },
    {
      landId: '2',
      land: 'Österreich',
    },
    {
      landId: '3',
      land: 'Frankreich',
    },
  ],
};

export const absenderDialogReducer = createReducer(
  initialAbsenderDialogState,

  on(
    AbsenderDialogActions.open,
    (state, action) => ({
      ...state,
      isOpen: true,
      absenderId: action.absenderId,
    })
  ),

  on(
    AbsenderDialogActions.close,
    () => ({
      ...initialAbsenderDialogState,
    })
  ),
);
