import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AppState} from '../../../store/states/app.state';
import {Store} from '@ngrx/store';
import {UploadLogoDialogActions} from '../../../store/actions/upload-logo-dialog.actions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NGXLogger} from 'ngx-logger';


enum ErrorMessageType {
  fileSize, fileType
}

interface ErrorMessage {
  errorType: ErrorMessageType;
  message: string;
}

interface UploadErrors {
  fileTypeError: ErrorMessage;
  fileSizeError: ErrorMessage;
}

@Component({
  selector: 'app-upload-logo-dialog',
  templateUrl: './upload-logo-dialog.component.html',
  styleUrls: ['./upload-logo-dialog.component.scss']
})
export class UploadLogoDialogComponent implements OnInit {
  @ViewChild('fileInput') fileInputElement!: ElementRef;
  isLogoUploaded: boolean = false;
  uploadedImage: string | null = null;
  errorMessageToDisplay: string | null = null;

  allPossibleUploadErrors: UploadErrors = {
    fileTypeError: {
      errorType: ErrorMessageType.fileType,
      message: 'Fehler: Ungültiges Dateiformat. Nur PNG- und JPG-Dateien sind erlaubt.'
    },
    fileSizeError: {
      errorType: ErrorMessageType.fileSize,
      message: 'Fehler: Die Datei ist zu groß. Überprüfe die Datei auf Einhaltung der Vorgaben vor dem Hochladen.'
    }
  };

  constructor(private store: Store<AppState>,
              private logger: NGXLogger,
              private snackbar: MatSnackBar) {
  }

  ngOnInit(): void {
    window.addEventListener('dragover', this.preventGlobalDrag, false);
    window.addEventListener('drop', this.preventGlobalDrop, false);
  }

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file = target.files ? target.files[0] : null;
    if (file) {
      this.processFile(file);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // TODO : we can add Additional visual feedback here
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // TODO : we Reset any visual changes made on drag over
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length === 1) {
      this.processFile(event.dataTransfer.files[0]);
    }
  }

  processFile(file: File): void {
    const validTypes = ['image/png', 'image/jpeg'];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (!validTypes.includes(file.type)) {
      this.errorOccurred(this.allPossibleUploadErrors.fileTypeError);
      this.logger.warn('Invalid file type. Only PNG and JPG are allowed.');
      return;
    }

    if (file.size > maxFileSize) {
      this.errorOccurred(this.allPossibleUploadErrors.fileSizeError);
      this.logger.warn('File is too large. Maximum allowed file size is 5 MB.');
      return;
    }

    if (file) {
      this.uploadedImage = URL.createObjectURL(file);
      this.isLogoUploaded = true;
    }
    this.resetFileInput();
    this.errorMessageToDisplay = null;
  }


  handleClickOfLogoViewer(): void {
    if (!this.isLogoUploaded) {
      this.fileInputElement.nativeElement.click();
    }
  }

  removeLogo(): void {
    this.uploadedImage = null;
    this.isLogoUploaded = false;
  }

  // TODO : implement data communication logic
  saveLogo() {
    this.store.dispatch(UploadLogoDialogActions.close());
    this.snackbar.open('Logo wurde erfolgreich hochgeladen', undefined, {
      duration: 2000,
    });
  }

  closeDialog() {
    this.store.dispatch(UploadLogoDialogActions.close());
  }

  private resetFileInput(): void {
    this.fileInputElement.nativeElement.value = '';
  }

  private preventGlobalDrag(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private preventGlobalDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private errorOccurred(error: ErrorMessage) {
    this.errorMessageToDisplay = error.message;
  }

  ngOnDestroy(): void {
    window.removeEventListener('dragover', this.preventGlobalDrag, false);
    window.removeEventListener('drop', this.preventGlobalDrop, false);
  }

}
