<div class="dialog-wrapper">
  <app-form
    [formInput]="formInputs"
    (cancelClicked)="closeDialogClick()"
  >
    <div *ngIf="absenderDto" class="header-content">
      Änderungen an bestehenden Kontaktdaten führen automatisch zur Aktualisierung der zugehörigen <b>Rechnungsentwürfe</b>.
    </div>
    <div
      class='formfield-wrapper body-content'>
      <jf-radio-button-group
        [formControl]="formControls.absenderType"
        [options]="absenderTypes"
        *ngIf="absenderTypes"
      ></jf-radio-button-group>

      <app-absender-privatperson-form
        [formControls]="this.formControlsPrivat"
        [laenderOptions]="laenderPrivat"
        [anredenOptions]="anreden"
        [formType]="absenderFormType"
        *ngIf="selectedAbsenderType === absenderPrivat">
      </app-absender-privatperson-form>

      <app-absender-geschaeftskunde-form
        [formControls]="this.formControlsGeschaeft"
        [laenderOptions]="laenderGeschaeft"
        [anredenOptions]="anreden"
        [formType]="absenderFormType"
        *ngIf="selectedAbsenderType === absenderGeschaeft">
      </app-absender-geschaeftskunde-form>

    </div>

    <div
      class='footer-content'>
      <jf-button
        (click)="confirmActionClick()"
        [style]="'primary'"
        [disabled]="!checkFormComplete()"
      >
        {{ primaryButtonLabel }}
      </jf-button>
    </div>
  </app-form>
</div>
