import {FakturierungsbelegFormState} from '../states/fakturierungsbeleg-form.state';
import {createReducer, on} from '@ngrx/store';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';


export const initialFakturierungsbelegFormState: FakturierungsbelegFormState = {
  vorlaufzeileMode: 'display',
};

export const fakturierungsbelegFormReducer = createReducer(
  initialFakturierungsbelegFormState,

  on(FakturierungsbelegFormActions.reset, () => initialFakturierungsbelegFormState),

  on(FakturierungsbelegFormActions.setVorlaufzeileMode, (state, {mode}) => ({
    ...state,
    vorlaufzeileMode: mode,
  })),

  on(FakturierungsbelegFormActions.setVorlaufzeileText, (state, {text}) => ({
    ...state,
    vorlaufzeileText: text,
  })),

  on(
    FakturierungsbelegFormActions.selectLeistungsempfaenger,
    (state, {absenderId}) => {
      return {
        ...state,
        absenderId,
      };
    }
  ),

);
