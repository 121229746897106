import {createAction, props} from '@ngrx/store';
import {AbsenderDTO, FilterAbsenderDTO, PageableAbsenderDTO} from '../../openapi/fakturierung-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class AbsenderLeistungsempfaengerActions {

  public static readAbsender = createAction(
    '[Fakturierungsbeleg-Form] Read Absender at leistungsempfaenger component.',
    props<{
      inhaberId: string,
      filterDto: FilterAbsenderDTO,
      pageableDto: PageableAbsenderDTO,
    }>(),
  );

  public static setDisplayedIds = createAction(
    '[Fakturierungsbeleg Entities] Set absenders ids that should be displayed at leistungsempfaenger component.',
    props<{
      absenderDtos: AbsenderDTO[],
    }>(),
  );

  public static clearDisplayedAbsender = createAction(
    '[Fakturierungsbeleg Entities] Clear absenders that should be displayed at leistungsempfaenger component.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static updateTextFilter = createAction(
    '[Fakturierungsbeleg-Form] Updates the text-filter',
    props<{
      textfilter: string[],
    }>(),
  );

  public static loadMoreOptions = createAction(
    '[Fakturierungsbeleg-Form] Updates the pageableDto to load more options',
  );

}
