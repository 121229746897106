import {EinheitDTO, EinheitDTOMengenartEnum} from '../../openapi/fakturierung-openapi';

export const einheitenMock: EinheitDTO[] = [
  {
    einheitId: '1',
    nummer: 100,
    kurzbezeichnung: 't',
    bezeichnung: 'Tonnen',
    mengenart: EinheitDTOMengenartEnum.Gewichtsmenge,
  },
  {
    einheitId: '2',
    nummer: 200,
    kurzbezeichnung: 'kg',
    bezeichnung: 'Kilogramm',
    mengenart: EinheitDTOMengenartEnum.Gewichtsmenge,
  },
  {
    einheitId: '3',
    nummer: 300,
    kurzbezeichnung: 'Stk.',
    bezeichnung: 'Stück',
    mengenart: EinheitDTOMengenartEnum.Stueckmenge,
  },
  {
    einheitId: '4',
    nummer: 14,
    kurzbezeichnung: 'sqrm',
    bezeichnung: 'Quadratmeter',
    mengenart: EinheitDTOMengenartEnum.Stueckmenge,
  },
  {
    einheitId: '5',
    nummer: 15,
    kurzbezeichnung: 'm',
    bezeichnung: 'Meter',
    mengenart: EinheitDTOMengenartEnum.Stueckmenge,
  }
];
