import {createAction, props} from '@ngrx/store';
import {Mode} from '../../modules/components/vorlaufzeile/mode.type';


export class FakturierungsbelegFormActions {

  public static reset = createAction(
    '[Fakturierungsbeleg Form] Reset this context.',
  );

  public static setVorlaufzeileMode = createAction(
    '[Fakturierungsbeleg Form] Change Vorlaufzeile Mode.',
    props<{
      mode: Mode,
    }>(),
  );

  public static setVorlaufzeileText = createAction(
    '[Fakturierungsbeleg Form] Set Vorlaufzeile Text.',
    props<{
      text: string,
    }>(),
  );

  public static selectLeistungsempfaenger = createAction(
    '[Fakturierungsbeleg Form] Select Leistungsempfänger',
    props<{
      absenderId?: string,
    }>(),
  );

}
