<div class="dialog-wrapper">

  <h1
    class="dialog-h1"
    i18n
  >
    Kontakt löschen
  </h1>

  <div
    class="beleg-data"
    i18n
  >
    <div *ngIf="absenderDto?.nachname || absenderDto?.vorname">
      <strong>{{ absenderDto?.vorname }} {{ absenderDto?.nachname }}</strong>
    </div>

    <div *ngIf="absenderDto?.firma">
      {{ absenderDto?.firma }}
    </div>

    <div *ngIf="absenderDto?.strasseundhausnummer || absenderDto?.plz || absenderDto?.ort">
      {{ absenderDto?.strasseundhausnummer }}, {{ absenderDto?.plz }} {{ absenderDto?.ort }}
    </div>

    <div *ngIf="absenderDto?.kundennummer">
      Kundennummer: {{ absenderDto?.kundennummer }}
    </div>

    <div class="hint">Ein gelöschter Kontakt kann nicht wiederhergestellt werden.</div>
  </div>

  <mat-divider></mat-divider>

  <div class="button-wrapper">

    <jf-button
      [variant]="'primary'"
      (doClick)="deleteAbsenderClick()"
      i18n
    >
      Löschen
    </jf-button>

    <jf-button
      [variant]="'ghost'"
      (doClick)="closeDialogClick()"
      i18n
    >
      Abbrechen
    </jf-button>
  </div>
</div>
