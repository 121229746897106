import {createAction, props} from '@ngrx/store';
import {LandDTO} from '../../openapi/fakturierung-openapi';


export class AbsenderDialogActions {

  public static open = createAction(
    '[Kontakt Dialog] Open kontakt dialog.',
    props<{
      absenderId: string | undefined
    }>(),
  );

  public static close = createAction(
    '[Kontakt Dialog] Close kontakt dialog.',
  );

  public static readLaender = createAction(
    '[Kontakt Dialog] Read laender.',
  );

  public static readLaenderSuccess = createAction(
    '[Kontakt Dialog] Read laender success.',
    props<{ laender: LandDTO[] }>(),
  );

  public static readLaenderFailure = createAction(
    '[Kontakt Dialog] Read laender failure.',
    props<{ error: any }>(),
  );
}
